// PROD!
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyA7svNMul42jSQUdRjoHhpq0we-bephsAk",
    authDomain: "culturehub-63214.firebaseapp.com",
    projectId: "culturehub-63214",
    storageBucket: "culturehub-63214.appspot.com",
    messagingSenderId: "172646244936",
    appId: "1:172646244936:web:0695fc6a4746a59bdd6e7d",
    measurementId: "G-N49SW6HWPG"
  },
  api_urls: {
    slackAuthLink: "https://slack.com/oauth/v2/authorize?client_id=679683179920.2793920736704&scope=channels:history,channels:read,chat:write,chat:write.public,commands,groups:read,users:read,users:read.email,groups:history&user_scope=channels:history,groups:read,channels:read,groups:history",
    getSlackChannels: "https://us-central1-culturehub-63214.cloudfunctions.net/getSlackChannels",
    getAccessToken: "https://us-central1-culturehub-63214.cloudfunctions.net/slack-getAccessToken"
  },
  sendgridAPI: {
    APIKey: "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"
  }
};