import { Injectable } from '@angular/core';
import { SnackBarComponent } from '../modules/shared/snack-bar/snack-bar.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '../models/contact.model';
import { DB } from '../configs/app.config';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _snackBar: MatSnackBar,
    private _af: AngularFirestore
  ) { }

  openSnackBar(message: string, isSuccess: boolean) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: {message: message},
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 3000,
      panelClass: [isSuccess ? 'success' : 'failure']
    });
  }

  async createContact(contact: Contact) {
    return this._af.collection(DB.contacts).add(contact).then(_=> {
      this.openSnackBar('Your message has been submitted successfully.', true);
      return true;
    }).catch(_ => {
      this.openSnackBar('An error occurred. Please try again later.', false);
      return false;
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  wordCountValidator(control: FormControl) {
    let field = (control.value || '').trim();
    if(field.split(' ').length <= 200) {
      return null;
    } else {
      return {'wordLimit': true}
    }
  }

  trimCheck(value: any) {
    const trimVal = value.trim();
    return trimVal !== '' ? trimVal : null;
  }

  openRightSideNav(width?: string) {
    const bodyWidth = document.body.clientWidth;
    document.getElementById("myCanvasNav").style.width = "100%";
    document.getElementById("myCanvasNav").style.opacity = "0.8";
    document.getElementById("mySidenav").style.width =width? width: bodyWidth < 1000 ? bodyWidth < 500 ? "100%" : "80%" : '40%';
    document.body.style.overflow = 'hidden';
    // document.getElementById("main").style.marginLeft = "60%";
  }

  closeRightSideNav() {
    document.getElementById("mySidenav").style.width = "0%";
    // document.getElementById("main").style.marginLeft= "0%";
    document.body.style.backgroundColor = "white";
    document.getElementById("myCanvasNav").style.width = "0%";
    document.getElementById("myCanvasNav").style.opacity = "0"; 
    document.body.style.overflowY = 'scroll';
  }

  openRightSideNavForOrg() {
    const bodyWidth = document.body.clientWidth;
    document.getElementById("myOrgCanvasNav").style.width = "100%";
    document.getElementById("myOrgCanvasNav").style.opacity = "0.8";
    document.getElementById("myOrgSidenav").style.width = bodyWidth < 1000 ? bodyWidth < 500 ? "100%" : "80%" : '40%';
    document.body.style.overflow = 'hidden';
  }

  closeRightSideNavForOrg() {
    document.getElementById("myOrgSidenav").style.width = "0%";
    document.body.style.backgroundColor = "white";
    document.getElementById("myOrgCanvasNav").style.width = "0%";
    // document.getElementById("myorgCanvasNav").style.opacity = "0"; 
    document.body.style.overflowY = 'scroll';
  }

  openRightSideNavForPeerReview(width?: string) {
    const bodyWidth = document.body.clientWidth;
    document.getElementById("myCanvasNavForPeer").style.width = "100%";
    document.getElementById("myCanvasNavForPeer").style.opacity = "0.8";
    document.getElementById("mySidenavForPeer").style.width =width? width: bodyWidth < 1000 ? bodyWidth < 500 ? "100%" : "80%" : '40%';
    document.body.style.overflow = 'hidden';
    // document.getElementById("main").style.marginLeft = "60%";
  }

  closeRightSideNavForPeerReview() {
    document.getElementById("mySidenavForPeer").style.width = "0%";
    // document.getElementById("main").style.marginLeft= "0%";
    document.body.style.backgroundColor = "white";
    document.getElementById("myCanvasNavForPeer").style.width = "0%";
    document.getElementById("myCanvasNavForPeer").style.opacity = "0"; 
    document.body.style.overflowY = 'scroll';
  }

  openRightSideNavForManager(width?: string) {
    const bodyWidth = document.body.clientWidth;
    document.getElementById("myCanvasNavForManager").style.width = "100%";
    document.getElementById("myCanvasNavForManager").style.opacity = "0.8";
    document.getElementById("mySidenavForManager").style.width =width? width: bodyWidth < 1000 ? bodyWidth < 500 ? "100%" : "80%" : '40%';
    document.body.style.overflow = 'hidden';
    // document.getElementById("main").style.marginLeft = "60%";
  }

  closeRightSideNavForManager() {
    document.getElementById("mySidenavForManager").style.width = "0%";
    // document.getElementById("main").style.marginLeft= "0%";
    document.body.style.backgroundColor = "white";
    document.getElementById("myCanvasNavForManager").style.width = "0%";
    document.getElementById("myCanvasNavForManager").style.opacity = "0"; 
    document.body.style.overflowY = 'scroll';
  }
}
