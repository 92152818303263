import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, finalize, lastValueFrom, Observable, take } from 'rxjs';
import { DB } from '../configs/app.config';
import { Member, Organization } from '../models/organization.model';
import { OrganizationAccess, User } from '../models/user.model';
import { CommonService } from './common.service';
import * as firebase from 'firebase/firestore';
import { Invitation } from '../models/invitation.model';
import { deleteField } from 'firebase/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private orgAccess$: BehaviorSubject<OrganizationAccess> = new BehaviorSubject(null);
  allMembers: BehaviorSubject<Member[]> = new BehaviorSubject([]);

  constructor(
    private _af: AngularFirestore,
    private _commonService: CommonService,
    private storage: AngularFireStorage,
  ) { 
    this.getOrgAccess$().subscribe(res => {
      if(res?.id){
        let orgId = res.id;
        this.getOrgMembers(orgId).then(members => {
          this.allMembers.next(members as Member[]);
        })
      }
    })
  }

  async getOrgAccessByUserId(userId: string) {
    const userOrgsAccess$ = this._af.collection(`${DB.users}/${userId}/${DB.organizations_access}`).get().pipe();
    return lastValueFrom(userOrgsAccess$).then(res => {
      return res.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data() as object
        } as OrganizationAccess;
      })
    }).catch(e => {
      this._commonService.openSnackBar('An error occurred while fetching organizations.', false);
      return false;
    });
  }

  getOrgAccess$(): Observable<OrganizationAccess> {
    return this.orgAccess$.asObservable();
  }

  setOrgAccess(orgAccess: OrganizationAccess) {
    this.orgAccess$.next(orgAccess);
  }

  async getOrgMembers(orgId: string) {
    const orgMembers = this._af.collection(`${DB.organizations}/${orgId}/${DB.members}`).get().pipe(take(1));
    return lastValueFrom(orgMembers).then(res => {
      return res.docs.map(x => {
        return {
          id: x.id,
          ...x.data() as object
        } as Member;
      })
    }).catch(e => {
      console.log(e)
      return false;
    });
  }

  async getOrgMember(orgId: string, memberId: string) {
    return this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${memberId}`).ref.get().then(res => {
      return {
        id: res.id,
        ...res.data() as Member
      } as Member;
    }).catch(_ => {
      return false;
    });
  }

  async updateManagerMapping(orgId: string, managerId: string, managerResources: string[]){
    if (managerResources?.length > 0) {
      const batch = this._af.firestore.batch();
      const memberDocRef = this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${managerId}`).ref;
      batch.update(memberDocRef, {
        'manager_of': managerResources
      });
      return batch.commit().then(_ => {
        return true;
      }).catch(_ => {
        return false;
      });
    } else {
      return await this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${managerId}`).update({
        'manager_of': deleteField()
      }).then(_ => {
        return true;
      }).catch(_ => {
        return false;
      });
    }
  }

  async getOrgInvitations(orgId: string) {
    return this._af.collection(`${DB.invitations}`).ref.where('org_id', '==', orgId).get().then(res => {
      return res.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data() as object
        } as Invitation;
      });
    }).catch(_ => {
      return false;
    });
  }

  async updateMemberRole(orgId: string, userId: string, role: string) {
    const batch = this._af.firestore.batch();
    const updatedBy = localStorage.getItem('userId');
    const orgAccessDocRef = this._af.doc(`${DB.users}/${userId}/${DB.organizations_access}/${orgId}`).ref;
    const memberDocRef = this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${userId}`).ref;
    batch.update(orgAccessDocRef, {
      'role': role
    });
    batch.update(memberDocRef, {
      'role': role,
      'updated_by': updatedBy,
      'updated_on': new Date().valueOf()
    });
    return batch.commit().then(_ => {
      this._commonService.openSnackBar('Member role updated successfully.', true);
      return true;
    }).catch(_ => {
      this._commonService.openSnackBar('Unable to update member role. Please try again later.', false);
      return false;
    });
  }

  async updateMemberDetails(orgId: string, member: Member){
    const batch = this._af.firestore.batch();
    const orgAccessDocRef = this._af.doc(`${DB.users}/${member?.id}/${DB.organizations_access}/${orgId}`).ref;
    const memberDocRef = this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${member.id}`).ref;
    batch.update(orgAccessDocRef,{
      'employment_status': member?.employment_status
    });
    delete member.id;
    batch.update(memberDocRef,{
      ...member
    });
    return batch.commit().then(_=>{
      return true;
    }).catch(_=>{
      return false;
    });
  }

  async deleteInvitation(invitationId: string) {
    return this._af.doc(`${DB.invitations}/${invitationId}`).delete().then(_ => {
      this._commonService.openSnackBar('User invitation deleted succssfully.', true);
      return true;
    }).catch(_ => {
      this._commonService.openSnackBar('Unable to delete user invitation. Please try again later.', false);
      return false;
    });
  }

  async resendInvitation(invitationId: string) {
    return this._af.doc(`${DB.invitations}/${invitationId}`).update({
      email_trigger: firebase.increment(1)
    }).then(_ => {
      this._commonService.openSnackBar('User invitation sent successfully.', true);
      return true;
    }).catch(_ => {
      this._commonService.openSnackBar('Unable to send user invitation. Please try again later.', false);
      return false;
    });
  }

  async removeMember(orgId: string, userId: string) {
    const batch = this._af.firestore.batch();
    const orgAccessDocRef = this._af.doc(`${DB.users}/${userId}/${DB.organizations_access}/${orgId}`).ref;
    const memberDocRef = this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${userId}`).ref;
    batch.delete(orgAccessDocRef);
    batch.delete(memberDocRef);
    return batch.commit().then(_ => {
      this._commonService.openSnackBar('Member successfully removed from this organization.', true);
      return true;
    }).catch(_ => {
      this._commonService.openSnackBar('Unable to remove member. Please try again later.', false);
      return false;
    });
  }

  async addMember(orgId: string, orgName: string, email: string, role: string) {
    try {
      const createdBy = localStorage.getItem('userId');
      const checkMember = await this._af.collection(`${DB.organizations}/${orgId}/${DB.members}`).ref
        .where('email', '==', email).get();
      if (checkMember.docs.length > 0) {
        this._commonService.openSnackBar('Member already part of this organization.', false);
        return false;
      }
      const checkUser = await this._af.collection(DB.users).ref.where('email', '==', email).get();
      if (checkUser.docs.length === 0) {
        const checkInvitation = await this._af.collection(DB.invitations).ref
          .where('email', '==', email)
          .where('org_id', '==', orgId).get();
        let invitation: Invitation;
        if (checkInvitation.docs.length > 0) {
          // TRIGGER EMAIL
          invitation = {} as Invitation;
          invitation.role = role;
          invitation.created_by = createdBy;
          invitation.email_trigger = checkInvitation.docs[0].data()['email_trigger'] + 1;
          invitation.created_on = new Date().valueOf();
          await checkInvitation.docs[0].ref.update(invitation);
          invitation.id = checkInvitation.docs[0].id;
        } else {
          // SEND 1ST INVITATION EMAIL
          invitation = {
            'email': email,
            'org_id': orgId,
            'role': role,
            'email_trigger': 1,
            'org_name': orgName,
            'created_by': createdBy,
            'created_on': new Date().valueOf()
          };
          const newInvitation = await this._af.collection(DB.invitations).ref.add(invitation);
          invitation.id = newInvitation.id;
        }
        this._commonService.openSnackBar('An email invitation has been successfully sent to the user for joining this organization.', true);
        return { resultType: 'invitation', data: invitation };
      } else {
        // DIRECTLY ADD USER
        const batch = this._af.firestore.batch();
        const userDoc = checkUser.docs[0].data();
        const userDocId = checkUser.docs[0].id;
        const orgAccessDocRef = this._af.doc(`${DB.users}/${userDocId}/${DB.organizations_access}/${orgId}`).ref;
        const memberDocRef = this._af.doc(`${DB.organizations}/${orgId}/${DB.members}/${userDocId}`).ref;
        const memberDocData = {
          'role': role,
          'email': email,
          'name': userDoc['name'] ? userDoc['name'] : null,
          'avatar': userDoc['avatar'] ? userDoc['avatar'] : null,
          'created_by': createdBy,
          'created_on': new Date().valueOf(),
          'updated_by': createdBy,
          'updated_on': new Date().valueOf(),
        } as Member;
        batch.set(orgAccessDocRef, {
          'role': role,
          'orgName': orgName
        }, { merge: true });
        batch.set(memberDocRef, memberDocData, { merge: true });
        return batch.commit().then(_ => {
          memberDocData.id = userDocId;
          this._commonService.openSnackBar('User successfully added to this organization.', true);
          return { resultType: 'member', data: memberDocData };
        });
      }
    } catch (e) {
      console.log(e);
      this._commonService.openSnackBar('An error occurred while adding member. Please try again later.', false);
      return false;
    }
  }

  yolo() {

    // this._af.collection('organizations').ref.get().then(res => {
    //   res.docs.map(x => {
    //     x.ref.collection('kudos').get().then().then(y => {
    //       y.docs.map(z => {
    //         z.ref.update({
    //           'userId': firebase.deleteField(),
    //           'id': firebase.deleteField()
    //         })
    //       });
    //     });
    //   });
    // });


    // const users = this._af.collection('users').get().pipe(take(1));
    // lastValueFrom(users).then(res => {
    //   res.docs.map(async x => {
    //     console.log('user: ', x.data()['email']);
    //     await x.ref.collection('organizations_access').get().then(y => {
    //       y.docs.map (async z => {
    //         const checkOrg = this._af.collection('organizations').doc(z.id).get().pipe(take(1));
    //         await lastValueFrom(checkOrg).then(async zz => {
    //           if(zz.exists) {
    //             console.log('exists org: ', z.data()['name']);
    //           } else {
    //             console.log('not-exists org: ', z.data()['name']);
    //             await z.ref.delete();
    //           }
    //         })
    //       });
    //     });
    //     console.log('');
    //     console.log('');
    //   });
    // });

    // const invitations = this._af.collection('invitations').get().pipe(take(1));
    // lastValueFrom(invitations).then(res => {
    //   res.docs.map(x => {
    //     const data = {
    //       'added_on': firebase.deleteField(),
    //       'invited_by': firebase.deleteField(),
    //       'orgName': firebase.deleteField(),
    //       'orgId': firebase.deleteField()
    //     };
    //     console.log(data);
    //     x.ref.update(data);
    //   });
    // });

    // const users = this._af.collection('users').get().pipe(take(1));
    // lastValueFrom(users).then(res => {
    //   res.docs.map(x => {
    //     x.ref.collection(DB.organizations_access).get().then(y => {
    //       // const batch = this._af.firestore.batch();
    //       y.docs.map(z => {
    //         console.log(z.id, x.data()['name'], x.data()['avatar'], z.data()['role']);
    //           // const memberDocRef = this._af.collection(`${DB.organizations}/${z.id}/${DB.members}`).doc(x.id);
    //           // batch.set(memberDocRef.ref, {
    //           //   'name': x.data()['name'],
    //           //   'avatar': x.data()['avatar'],
    //           //   'email': x.data()['email'],
    //           //   'role': z.data()['role'],
    //           //   'is_deleted': firebase.deleteField()
    //           // }, {merge: true});
    //       });
    //       // batch.commit();
    //     });
    //   })
    // });
  }
  async getOrgDetailsById(orgId: string) {
    return await this._af.doc(`${DB.organizations}/${orgId}`).ref
      .get()
      .then((res) => {
        return {
          id: res.id,
          ...res.data() as Object
        } as Organization
      }).catch(_ => {
        this._commonService.openSnackBar('An error occurred while fetching organizations.', false);
        return false;
      })

  }

  async getUserDetailsByEmail(email: string) {
    return await this._af.collection(DB.users).ref.where('email', '==', email).get()
      .then((res) => {
        return res.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data() as Object
          } as User;
        });
      }).catch(_ => {
        return false;
      })

  }

  async uploadOrgImage(fileUpload: File, organization: Organization, organizationsAccess: OrganizationAccess, members: Member[]) {
    try {
      var orgId = this._af.createId();
      var filePath = `/organizations/${orgId}`;
      const uploadImage = await this.storage.upload(filePath, fileUpload);
      const url = await uploadImage.ref.getDownloadURL();
      organization.image_url = url;
      return await this.createOrganization(organization, organizationsAccess, members, orgId);
    } catch (_) {
      this._commonService.openSnackBar('An error occurred while uploading org image. Please try again later.', false);
      return false;
    }

  }

  async changeOrgImage(orgId: string, fileUpload: File, organization: Organization) {
    try {
      var filePath = `/organizations/${orgId}`;
      const uploadImage = await this.storage.upload(filePath, fileUpload);
      const url = await uploadImage.ref.getDownloadURL();
      organization.image_url = url;
      return await this.updateOrganization(orgId, organization);
    } catch (_) {
      this._commonService.openSnackBar('An error occurred while uploading org image. Please try again later.', false);
      return false;
    }
  }

  async createOrganization(organization: Organization, organizationsAccess: OrganizationAccess, members: Member[], orgId?: string) {
    const docId = orgId ? orgId : this._af.createId();
    const batch = this._af.firestore.batch();
    const createdBy = localStorage.getItem('userId');
    const orgDocRef = this._af.doc(`${DB.organizations}/${docId}`).ref;
    const orgAccessDocRef = this._af.doc(`${DB.users}/${createdBy}/${DB.organizations_access}/${docId}`).ref;
    batch.set(orgDocRef, organization);
    batch.set(orgAccessDocRef, organizationsAccess);
    members.forEach((member) => {
      const memberDocRef = this._af.doc(`${DB.organizations}/${docId}/${DB.members}/${member.id}`).ref;
      delete member['id'];
      batch.set(memberDocRef, member);
    })
    return batch.commit().then(_ => {
      this._commonService.openSnackBar('Org has been created successfully.', true);
      return docId;
    }).catch(_ => {
      this._commonService.openSnackBar('An error occurred while creating org. Please try again later.', false);
      return false;
    });

  }

  async updateOrganization(orgId: string, organization: Organization) {
    const membersColRef = this._af.collection(`${DB.organizations}/${orgId}/${DB.members}`).ref;
    const orgDocRef = this._af.doc(`${DB.organizations}/${orgId}`).ref;
    const batch = this._af.firestore.batch();
    batch.update(orgDocRef, organization);
    try {
      (await membersColRef.get()).docs.forEach((doc) => {
        const orgAccessDocRef = this._af.doc(`${DB.users}/${doc.id}/${DB.organizations_access}/${orgId}`).ref;
        batch.set(orgAccessDocRef, { name: organization.name }, { merge: true });
      });
      return batch.commit()
        .then(_ => {
          this._commonService.openSnackBar('Org has been updated successfully.', true);
          return true;
        });
    } catch (_) {
      this._commonService.openSnackBar('An error occurred while updating org. Please try again later.', false);
      return false;
    }
  }

}
