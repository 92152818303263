// export const APP_VERSION = '5.1.2';

export const DB = {
    users: 'users',
    user_details: 'user_details',
    kudos: 'kudos',
    organizations: 'organizations',
    organizations_access: 'organizations_access',
    snippets: 'snippets',
    members: 'members',
    settings: 'settings',
    perf_review_cycles: 'perf_review_cycles',
    self_assessments: 'self_assessments',
    assessment_reviews: 'assessment_reviews', 
    okrs_lookup: 'okrs_lookup',
    objectives: 'objectives_keys_results',
    goals: 'goals',
    tasks: 'tasks',
    invitations: 'invitations',
    slackchannels: 'slackchannels',
    contacts: 'contacts',
    surveys: 'surveys',
    survey_result_details: 'survey_result_details',
    user_ratings: 'user_ratings',
    weeklyReports: 'weeklyReport',
    monthlyReports: 'monthlyReport',
    support: 'user-support',
    reports: 'reports',
    subscribe: 'subscription',
    customers: 'subscriptions',
    leaves: 'leaves',
    leavesQueue: 'leavesQueue'
}

export const MAIN_NAV = [
    {
        label: 'Dashboard',
        link: './dashboard',
        icon: 'explore',
        index: 0
    },
    {
        label: 'Snippets',
        link: './snippets',
        icon: 'checklist',
        index: 1
    },
    {
        label: 'Kudos',
        link: './kudos',
        icon: 'workspace_premium',
        index: 2
    },
    {
        label: 'Surveys',
        link: './surveys',
        icon: 'list_alt',
        index: 3
    },
    {
        label: 'Perf',
        link: './perfs',
        icon: 'rate_review',
        index: 4
    },
    {
        label: 'Members',
        link: './members',
        icon: 'group',
        index: 5
    },
    {
         label: 'Reportees',
         link: './reportees',
         icon: 'assignment',
         index: 6
     },
    // {
    //      label: 'Settings',
    //      link: './settings',
    //      icon: 'settings',
    //      index: 7
    // },
    {
        label: 'Leaves',
        link: './leaves',
        icon: 'spa',
        index: 7,
    }
];

export const PERFS_NAV = [
    {
        label: 'Self Assessments',
        link: './self-assessments'
    },
    {
        label: 'Peer Reviews',
        link: './peer-reviews'
    },
    {
        label: 'Stacked Ranks',
        link: './stacked-ranks'
    }
];

export const PERF_CYCLE_PHASES = {
    'phase1': 'Phase 1: Self Assessment',
    'phase2': 'Phase 2: Peers Reviews',
    'phase3': 'Phase 3: Manager & Final Review',
};

export const PERF_CYCLE_PHASES_LIST = [
    'phase1', 'phase2', 'phase3', 'finished'
];

export const PEER_MANAGER_APPRICIATIONS = [
    'Not Appriciated', 'Could Be Better', 'Neutral', 'Well Appriciated', 'Very Well Appriciated'
];

export const FINAL_FEEDBACK = [
    'Superb', 'Strongly exceeds expectations', 'Exceeds expectations', 
    'Meets expectations', 'Occasionally misses expectations', 'Needs improvement'
];

export const RATINGS = {
    1: 'Superb',
    2: 'Strongly exceeds expectations',
    3: 'Exceeds Expectations',
    4: 'Meets Expectations',
    5: 'Occasionally misses expectations',
    6: 'Needs Improvement'
}

export const SNIPS_NAV = [
    {
        label: 'All Snippets',
        link: './snippets'
    },
    {
        label: 'My Snippets',
        link: './my-snippets'
    }
];

export const MODULES = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': ['Lato,sans-serif'] }],
        [{ 'align': [] }],

        ['link'],
        ['clean'],                                         // remove formatting button
    ]
};