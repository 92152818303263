import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { DB } from '../configs/app.config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user$: Observable<User>;
  firebaseUser$: Observable<firebase.User>;
  constructor(
    private _afAuth: AngularFireAuth,
    private _af: AngularFirestore,
    private _router: Router
  ) {
    this.firebaseUser$ = this._afAuth.user;
    this.user$ = this._afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          localStorage.setItem('userId', user.uid);
          localStorage.setItem('email', user.email);
          return this._af.doc<User>(`${DB.users}/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  async loginWithGoogle() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      return await this._afAuth.signInWithPopup(provider).then(async result => {
        // if (result.additionalUserInfo.isNewUser) {
        //   await this.createUser(result.user);
        // }
        localStorage.setItem('userId', result.user.uid);
        localStorage.setItem('email', result.user.email);
        return result.user;
      });
    } catch (e) {
      return undefined;
    }
  }

  // async createUser(firebaseUser: firebase.User) {
  //   let formatedEmail = firebaseUser.email.split('@')[0].split('.').join('') + '@' + firebaseUser.email.split('@')[1];
  //   var user: User = {
  //     id: firebaseUser.uid,
  //     name: firebaseUser.displayName ? firebaseUser.displayName : firebaseUser.email.split('@')[0],
  //     email: formatedEmail,
  //     avatar: firebaseUser.photoURL ?? null,
  //     created_on: Date.now().valueOf(),
  //     is_deleted: false,
  //     created_by: firebaseUser.uid,
  //     updated_by: firebaseUser.uid,
  //     updated_on: Date.now().valueOf(),
  //   }
  //   this._af.doc<User>(`${DB.users}/${firebaseUser.uid}`).set(user).then(result => {
  //   }).catch(error => {
  //     console.log("error")
  //   });
  // }

  async getUserId() {
    const user = await this._afAuth.authState.pipe(first()).toPromise();
    return user.uid;
  }

  async getUser() {
    const user = await this._afAuth.authState.pipe(first()).toPromise();
    return this._af.collection<User>('users').doc(user.uid).ref.get().then(result => {
      return {
        id: result.id,
        ...result.data()
      } as User;
    });
  }

  async isUserLoggedIn() {
    const user = JSON.parse(localStorage.getItem('userId'));
    return user !== null;
  }

  async logout() {
    await this._afAuth.signOut();
    localStorage.clear();
    this._router.navigate(['/login'])
  }

  async searchUser(txt: string) {
    return this._af.collection<User>(`${DB.users}`)
      .ref.where('email', '==', txt)
      .get()
      .then(result => {
        return result.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data()
          } as User
        })
      });
  }
}