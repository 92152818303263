import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  contactForm: FormGroup;
  isLoading: boolean = false;
  featuresData = [
    {
      'img_url': '/assets/images/multipleOrg.png',
      'text': 'Manage multiple organization'
    },
    {
      'img_url': '/assets/images/snippet.png',
      'text': 'Snippets to review work'
    },
    {
      'img_url': '/assets/images/kudos.png',
      'text': 'Kudos to appriciate team members'
    },
    {
      'img_url': '/assets/images/report.png',
      'text': 'Receive email updates'
    },
    {
      'img_url': '/assets/images/okr.png',
      'text': 'OKR for maintaining company goals'
    },
    {
      'img_url': '/assets/images/slack.png',
      'text': 'Connect with slack'
    }
  ]

  constructor(
    private _fb: FormBuilder,
    private _commanService: CommonService
  ) {
  }

  ngOnInit() {
    this.contactForm = this._fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  get name() {
    return this.contactForm.get('name');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get message() {
    return this.contactForm.get('message');
  }

  saveClient() {
    if(this.contactForm.valid) {
      this.isLoading = true;
      const data: Contact = {
        name: this.name.value,
        email: this.email.value,
        message: this.message.value,
        created_on: new Date().valueOf()
      };
      this._commanService.createContact(data).then(res => {
        if(res) {
          this.contactForm.reset();
        }
        this.isLoading = false;
      }); 
    }
  }

}
