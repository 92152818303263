<div class="section1">
    <div class="backgroundColorDiv"></div>
    <div>
        <img class="mainTopImg" src="/assets/images/mainTop.png">
        <div class="head1">Start managing your company with</div>
        <div class="cultureHub">Culture Hub</div>
        <div class="getStartedBtn" routerLink="/login">GET STARTED</div>     
    </div>
</div>
<div class="section" style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-12" style="margin-bottom: 60px;">
                <div class="sectionHead">HOW IT WORKS?</div>
            </div>
            <img class="d-none d-md-block" src="/assets/images/h_howitworks.png" alt="" width="100%">
            <img class="d-md-none d-block mx-auto" src="/assets/images/v_howitworks.png" alt=""
                width="80%">
        </div>
    </div>
</div>
<div class="section">
    <div class="bgSection" style="background-color: teal;"></div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="sectionHead">FEATURES</div>
            </div>
            <div *ngFor="let ft of featuresData" class="col-lg-4 mt-5">
                <div class="fMain">
                    <div class="fImgDiv">
                        <img class="fImg" src="{{ft.img_url}}">
                    </div>
                    <div class="fText">{{ft.text}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section">
    <div class="bgSection" style="background-color: #fff;"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12" style="margin-bottom: 60px; text-align: center;">
                <div class="sectionHead">CONTACT US</div>
                <div class="contactSub">For any enquires, support or help, please let us know 
                    by leaving a message and we will get back to you.</div>
            </div>
            <div class="col-md-8" style="text-align: center;">
                <form [formGroup]="contactForm">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Enter your name" formControlName="name">
                        <mat-error *ngIf="name.hasError('required')">Please enter your name</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Enter your email" formControlName="email">
                        <mat-error *ngIf="email.hasError('required')">Please enter your email</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Message</mat-label>
                        <textarea matInput placeholder="How can we help you?" formControlName="message"></textarea>
                        <mat-error *ngIf="message.hasError('required')">
                            How can we help you?
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="primary" [disabled]="isLoading" (click)="saveClient()">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>