import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { OrganizationService } from './services/organization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  authSubs: Subscription;

  constructor(
    private _authService: AuthService,
    private _orgService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.authSubs = this._authService.firebaseUser$.subscribe(res => {
      if(res) {
        const userId = localStorage.getItem('userId');
        this._orgService.getOrgAccessByUserId(userId).then(res => {          
          if(res) {
            if((<any>res).length > 0) {
              this._orgService.setOrgAccess(res[0]);
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.authSubs?.unsubscribe();
  }

}
